import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPage = () => (
    <Layout>
        <SEO title="Privacy Policy"/>
        <div className={"container"}>
            <div className={"content"}>
                <div className={"title"}>
                    <h1>Privacy Policy</h1>
                </div>
                
                <h3>GENERAL</h3>
                <p>a. Sal-Tech Easy Packaging v/Gunnar Salbæk  ("Sal-Tech”, “us", "we", or "our") operates the sal-tech.com website (the "Service") to any user (“you”) who makes use of our service.</p>
                <p>b. This document informs you of our policies regarding the collection, use, and disclosure of personal data when you use our service and the choices you have associated with that data.</p>
                <p>c. We use your data to provide and improve our services. By using our services, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from sal-tech.com</p>
                <p>d. We collect several different types of information for various purposes to provide and improve our services provided to you.</p>
                <p>e. We are legally required to tell you about your rights and our obligations with regards to the collection, use, and disclosure of your personal data. To get in-depth details about your e-privacy rights.</p>
                <p>f. We have updated our privacy policy after the enforcement of the GDPR on the 25th of May 2018, so that our privacy policy complies with EU Law.</p>
                <p>g. Essentially, the GDPR entails that we now have in place watertight consent management processes for the protection of your data stored by us. Fundamentally, you now have strengthened data privacy rights and we cannot store your data without your consent.</p>
                <p>h. If you visit our web-site from any country situated outside of the European Union, do note that we have watertight consent management processes in place and that we strive to meet international standards set forth for the purposes of data protection. In other words, your data will be stored for the purposes outlined in this document and in compliance with EU e-privacy law.</p>
                <p>i. We do not share or disclose any information collected through our website to a third party without your consent.</p>
                <p>j. You may withdraw your consent at any time by instructing us support@sal-tech.com.</p>
                

                <h3>TYPES OF DATA COLLECTED</h3>
                <p>a. Personal Data while using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally, identifiable information may include, but is not limited to;</p>
                <p>a.1. Email address</p>
                <p>a. 2. First name and last name</p>
                <p>a.3. Phone number</p>
                <p>a.4. Address, State, Province, ZIP/Postal code, City</p>
                <p></p>
                <p>b. Payment data. Depending on your payment method, we never store your payment details (credit card, debit card etc) without your consent. If you use a secured third-party payment gateway such as paypal or skrill, then we are released from any liability in relation to that payment and data stored by that third party payment gateway. </p>
                

                <h3>COOKIES AND USAGE DATA</h3>
                <p>a. Usage Data, we may also collect information how the Service is accessed and used ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our web-site that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>

                <h3>TRACKING AND COOKIES DATA</h3>
                <p>a. We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyse our Service. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>

                <h3>EXAMPLES OF COOKIES WE USE</h3>
                <p>a. Session Cookies: We use Session Cookies to operate our Service.</p>
                <p>b. Preference Cookies: We use Preference Cookies to remember your preferences and various settings.</p>
                <p>c. Security Cookies: We use Security Cookies for security purposes.</p>

                <h3>USE OF DATA</h3>
                <p>Sal-Tech uses the collected data for various purposes</p>
                <p>a. To provide and maintain the Service.</p>
                <p>b. To notify you about changes to our Service.</p>
                <p>c. To allow you to participate in interactive features of our Service when you choose to do so.</p>
                <p>d. To provide customer care and support.</p>
                <p>e. To provide analysis or valuable information so that we can improve the Service.</p>
                <p>f. To monitor the usage of the Service.</p>
                <p>g.To detect, prevent and address technical issues.</p>

                <h3>TRANSFER OF DATA</h3>
                <p>a. Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>
                <p>b. If you are located outside Denmark and choose to provide information to us, please note that we transfer the data, including Personal Data, to that country and may process it there.</p>
                <p>c. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
                <p>d. Sal-Tech will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>
                
                <h3>DISCLOSURE OF DATA</h3>
                <p>Sal-Tech may disclose your Personal Data in the good faith belief that such action is necessary to;</p>
                <p>a. To comply with a legal obligation</p>
                <p>b. To protect and defend the rights or property of Sal-Tech</p>
                <p>c. To prevent or investigate possible wrongdoing in connection with the Service</p>
                <p>d. To protect the personal safety of users of the Service or the public</p>
                <p>e. To protect against legal liability</p>

                <h3>SECURITY OF DATA</h3>
                <a href="https://sal-tech.com/en/content/39-privacy-policy" target={"_blank"}>Read more</a>

            </div>
        </div>
    </Layout>
)

export default PrivacyPage
